// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'

// Constants
import { ActionFields } from '@/Constants/Global/ActionFields'
import { AppValues }    from '@/Constants/Global/AppValues'

// Store
import Store from '@/Store/Global/Default'

export function parseDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data, ActionFields.Edit)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idOperator', '_idStorage', '_idRole',  'permissions',
		'mLastName', 'dateBirth','isValid',  'licenses', 'certifications'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add('rut', 'Rut').isSortable().setAlign(null, 'center')
		.add('name', 'Nombre').isSortable()
		.add('pLastName', 'Apellido').isSortable()
		.add('mStorageCode', 'Código').isSortable()
		.add('mStorageName', 'Ubicación').isSortable()
		.add('mDateBirth', 'Fecha Nacimiento').isSortable()
		.add('mLicenses', 'Licencias').isSortable()
		.add('mCertifications', 'Certificaciones').isSortable()
		.add('position', 'Cargo').isSortable()
		.add('mIsValid', 'Estado').isSortable()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, [
		'rut', 'name', 'pLastName', 'mStorageCode', 'mStorageName', 'mDateBirth', 'position', 'mLicenses', 'mCertifications','mIsValid'
	])

	// Aplicar un valor a los campos e items si no estan presentes.
	bm.parseItemsMissingFields(items, new ItemMissingFields()
		.add('mCertifications')
		.add('mDateBirth')
		.add('mLicenses')
		.add('mStorageCode')
		.add('mStorageName')
		.add('mIsValid')
	)

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		mDateBirth: (_: never, item: any) => {
			const { dateBirth } = item
			if (!dateBirth) return AppValues.Strings.DEFAULT_EMPTY_STRING
			const date = PrimitiveTools.Dates.parseDateString(dateBirth)
			return date ? date.date : AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		mIsValid: (_: void, item: any) => {
			return item.isValid ? 'Activo' : 'Inactivo'
		},

		mStorageCode: (_: never, item: any) => {
			const { _idStorage } = item
			if (_idStorage) {
				const storage = Store.getters.getStoredStorageById(_idStorage)
				return storage ? storage.code : AppValues.Strings.DEFAULT_EMPTY_STRING
			}
			return AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		mStorageName: (_: never, item: any) => {
			const { _idStorage } = item
			if (_idStorage) {
				const storage = Store.getters.getStoredStorageById(_idStorage)
				return storage ? storage.name : AppValues.Strings.DEFAULT_EMPTY_STRING
			}
			return AppValues.Strings.DEFAULT_EMPTY_STRING
		},

		mLicenses: (_: never, item: any) => {
			return PrimitiveTools.Arrays.map<any>(item.licenses, (e) => {
				const validExpirationDate = e.expirationDate ? ` (Vigencia: ${ e.expirationDate })` : ''
				return e.name ? `${ e.name }${ validExpirationDate }` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}, () => AppValues.Strings.DEFAULT_EMPTY_STRING)
		},

		mCertifications: (_: never, item: any) => {
			return PrimitiveTools.Arrays.map<any>(item.certifications, (e) => {
				const validExpirationDate = e.expirationDate ? ` (Vigencia: ${ e.expirationDate })` : ''
				return e.name ? `${ e.name }${ validExpirationDate }` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}, () => AppValues.Strings.DEFAULT_EMPTY_STRING)
		},
		
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}

export function parseStackedDataResponse(data: Array<any>) {
	// Clase Constructora.
	const bm = new BuilderManager(data)
	const { fields, items, actions } = bm.getElements()

	// Eliminar los campos que no queremos mostrar en la tabla.
	bm.removeFields(fields, [
		'_idOperator', '_idStorage', '_idRole',  'permissions',
		'mLastName', 'dateBirth','isValid',  'licenses', 'certifications','rut','name','position','pLastName'
	])

	// Aplicar opciones a los campos.
	bm.parseFieldsOptions(fields, new FieldsOptions()
		.add(`name`, 'Nombre').isSortable().setAlign(null, 'center').showDetailsButton().showDetailsButton()
	)

	// Aplicar un nuevo Orden a todas las columnas visibles.
	bm.rearrangeFields(fields, ['name'])

	// Definir los formatos que serán aplicados al valor de la celda.
	bm.parseItemsFormatters(items, {
		name: (_: never, item: any) => {
			const { name, pLastName, _idStorage } = item
			if (name) {
				
				if (_idStorage) {
					const storage = Store.getters.getStoredStorageById(_idStorage)
					return name ? `${name} ${pLastName}` : AppValues.Strings.DEFAULT_EMPTY_STRING
				}}
			return AppValues.Strings.DEFAULT_EMPTY_STRING
		},
	})

	// Definir los campos a mostrar en el Detalle.
	bm.parseItemsDetail(items, {
		['Dirección']: ({ _idStorage }) => {
			if (_idStorage) {
				const storage = Store.getters.getStoredStorageById(_idStorage)
				return storage ? ` ${storage.name}` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}
		return AppValues.Strings.DEFAULT_EMPTY_STRING
	
		},

		['Rut']: ({ rut }) => {
			return rut;
		},

		['Cargo']: ({ position }) => {
			return position
		},

		['Cod. Ubicacion']: ({ _idStorage }) => {
			if (_idStorage) {
				const storage = Store.getters.getStoredStorageById(_idStorage)
				return storage ? storage.code : AppValues.Strings.DEFAULT_EMPTY_STRING
			}
			return AppValues.Strings.DEFAULT_EMPTY_STRING
			
		},

		['Licencias']: ({ licenses }) => {
			return PrimitiveTools.Arrays.map<any>(licenses, (e) => {
				const validExpirationDate = e.expirationDate ? ` (Vigencia: ${ e.expirationDate })` : ''
				return e.name ? `${ e.name }${ validExpirationDate }` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}, () => AppValues.Strings.DEFAULT_EMPTY_STRING)
		},

		['Certificaciones']: ({ certifications }) => {
			return PrimitiveTools.Arrays.map<any>(certifications, (e) => {
				const validExpirationDate = e.expirationDate ? ` (Vigencia: ${ e.expirationDate })` : ''
				return e.name ? `${ e.name }${ validExpirationDate }` : AppValues.Strings.DEFAULT_EMPTY_STRING
			}, () => AppValues.Strings.DEFAULT_EMPTY_STRING)
		},
		
		['Acciones']: ActionFields.Edit
	})

	// Retornar Objeto con los Campos, Items y Acciones.
	return { fields, items, actions }
}