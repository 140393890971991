// Classes
import { RutTools } from '@/Classes/Static/RutTools'

// Dependencies
import Vue from 'vue'

// Component Extend
const InputRut = Vue.extend({
	name: 'InputRut',

	props: {
		disabled: Boolean,
		hint: String,
	},

	data: function() {
		return {
			value: ''
		}
	},

	methods: {
		_getState: function() {
			if (this.value === '') return null
			return RutTools.validator(this.value)
		},
		
		clear: function() {
			this.value = ''
		},

		getValue: function() {
			return this.value
		},

		setValue: function(value: string) {
			this.value = value
		},

		onKeyUp: function() {
			if (this.value === '') return
			this.value = RutTools.formatter(this.value)
		}
	}
})

// Exports
export default InputRut
export type InputRutRef = InstanceType<typeof InputRut>